<template>
  <main class="main-page">
    <section class="page-section mb-3">
      <div class="overflow-x-hidden antialiased">

        <div class="relative items-center justify-center w-full overflow-x-hidden lg:pt-40 lg:pb-40 xl:pt-40 xl:pb-30">
          <div
            class="container flex flex-col items-center justify-between h-full max-w-6xl px-8 mx-auto -mt-32 lg:flex-row xl:px-0">
            <div
              class="z-30  ml-2 flex flex-col items-center w-full max-w-xl pt-48 text-center lg:items-start lg:w-1/2 lg:pt-20 xl:pt-40 lg:text-left">
              <h1 class="relative mb-4 text-6xl font-black leading-tight text-gray-900 sm:text-6xl xl:mb-4">
                Revolutionizing Student Accommodation & Living for the Modern Era
              </h1>
              <p class="pr-0 mb-4 text-base text-gray-600 sm:text-lg xl:text-2xl lg:pr-20">
                Welcome to the official portal for {{ $appName }} booking and resident management
              </p>
              <Button @click="showRooms" label="Book a Room" class="text-3xl px-5" size="large" />
              <!-- Integrates with section -->
              <div class="flex-col mt-5 sm:flex lg:mt-24">
                <p class="mb-4 text-sm font-medium tracking-widest text-gray-500 uppercase">
                  Integrates With
                </p>
                <div class="flex">
                  <img src="images/payment_methods.png" class="max-w-sm h-auto" />
                </div>
              </div>
            </div>

            <div
              class="hidden-xs hidden-sm relative z-50 flex flex-col items-end justify-center w-full h-full lg:w-1/2 ms:pl-10">
              <svg class="top-5 left-0 hidden w-screen max-w-2xl  -ml-12 lg:block" viewBox="0 0 818 815"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="c">
                    <stop stop-color="#ff7448" offset="0%" />
                    <stop stop-color="#ff7448" offset="100%" />
                  </linearGradient>
                  <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="f">
                    <stop stop-color="#f2a672" offset="0%" />
                    <stop stop-color="#f2a672" offset="100%" />
                  </linearGradient>
                  <filter x="-4.7%" y="-3.3%" width="109.3%" height="109.3%" filterUnits="objectBoundingBox" id="a">
                    <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" in="shadowBlurOuter1" />
                  </filter>
                  <filter x="-4.7%" y="-3.3%" width="109.3%" height="109.3%" filterUnits="objectBoundingBox" id="d">
                    <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" />
                  </filter>
                  <path
                    d="M160.52 108.243h497.445c17.83 0 24.296 1.856 30.814 5.342 6.519 3.486 11.635 8.602 15.12 15.12 3.487 6.52 5.344 12.985 5.344 30.815v497.445c0 17.83-1.857 24.296-5.343 30.814-3.486 6.519-8.602 11.635-15.12 15.12-6.52 3.487-12.985 5.344-30.815 5.344H160.52c-17.83 0-24.296-1.857-30.814-5.343-6.519-3.486-11.635-8.602-15.12-15.12-3.487-6.52-5.343-12.985-5.343-30.815V159.52c0-17.83 1.856-24.296 5.342-30.814 3.486-6.519 8.602-11.635 15.12-15.12 6.52-3.487 12.985-5.343 30.815-5.343z"
                    id="b" />
                  <path
                    d="M159.107 107.829H656.55c17.83 0 24.296 1.856 30.815 5.342 6.518 3.487 11.634 8.602 15.12 15.12 3.486 6.52 5.343 12.985 5.343 30.816V656.55c0 17.83-1.857 24.296-5.343 30.815-3.486 6.518-8.602 11.634-15.12 15.12-6.519 3.486-12.985 5.343-30.815 5.343H159.107c-17.83 0-24.297-1.857-30.815-5.343-6.519-3.486-11.634-8.602-15.12-15.12-3.487-6.519-5.343-12.985-5.343-30.815V159.107c0-17.83 1.856-24.297 5.342-30.815 3.487-6.519 8.602-11.634 15.12-15.12 6.52-3.487 12.985-5.343 30.816-5.343z"
                    id="e" />
                </defs>
                <g fill="none" fill-rule="evenodd" opacity=".2">
                  <g transform="rotate(65 416.452 409.167)">
                    <use fill="#000" filter="url(#a)" xlink:href="#b" />
                    <use fill="url(#c)" xlink:href="#b" />
                  </g>
                  <g transform="rotate(29 421.929 414.496)">
                    <use fill="#000" filter="url(#d)" xlink:href="#e" />
                    <use fill="url(#f)" xlink:href="#e" />
                  </g>
                </g>
              </svg>
              <div class="container relative left-0 w-full max-w-2xl lg:absolute xl:max-w-2xl lg:w-screen">
                <img src="images/caller.png"
                  class="w-full h-auto mt-20  ml-0 lg:mt-24 xl:mt-40 lg:mb-0 lg:h-full lg:-ml-12" />
              </div>

            </div>
          </div>
        </div>


        <div class="relative w-full px-2 py-8  border-gray-200 md:py-16 lg:py-24 xl:py-40 xl:px-0">
          <div class="container flex flex-col items-center justify-between h-full max-w-6xl mx-auto">
            <h2 class="my-5 text-base font-medium tracking-tight text-orange-500 uppercase">
              Why Choose Us
            </h2>
            <h3
              class="max-w-2xl px-5 mt-2 mb-4 text-3xl font-black leading-tight text-center text-gray-900 sm:mt-0 sm:px-0 sm:text-6xl">
              Let's Take It Up A Notch
            </h3>
            <div class="grid mt-0">
              <div class="col-12">
                <div class="card nice-shadow-7 bg-gray-100 px-6">
                  <div class="text-xl font-black font-bold">
                    Seamless Convenience
                  </div>
                  <div class="mt-2 text-gray-600">
                    About 800 meters from campus, we ensure you enjoy unparalleled convenience during your academic journey.
                    Say goodbye to long commutes and hello to extra sleep or study time.
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card nice-shadow-7 bg-gray-200 px-6">
                  <div class="text-xl font-black font-bold">
                    Assured Security and Serenity
                  </div>
                  <div class="mt-2 text-gray-600">
                    Experience a tranquil living environment with comprehensive security measures, including 24/7
                    surveillance, ensuring your peace of mind throughout your stay.
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card nice-shadow-7 bg-gray-100 px-6">
                  <div class="text-xl font-black font-bold">
                    Easy Access to Amenities and Services
                  </div>
                  <div class="mt-2 text-gray-600">
                    Experience easy access to a wide range of amenities and services at our hostel, including seamless
                    WiFi, hassle-free deliveries, on-site laundry facilities, and a well-stocked convenience shop,
                    ensuring your stay is convenient, connected, and comfortable.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="relative w-full px-2 py-4  border-gray-200 md:py-16 lg:py-24 xl:py-40 xl:px-0">
          <div class="container flex flex-col items-center justify-between h-full max-w-6xl mx-auto">
            <h2 class="my-5 text-base font-medium tracking-tight text-orange-500 uppercase">
              Tour
            </h2>
            <h2
              class="text-4xl font-extrabold text-center leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              A Sneak Peek Into Our Rooms
            </h2>

            <div class="max-w-full mx-auto mt-8 md:max-w-6xl sm:px-8">
              <image-viewer-custom preview-size="rooms" :src="images()" width="100%" height="100%" :num-display="20">
              </image-viewer-custom>
            </div>
          </div>
        </div>


        <div class="relative w-full px-2 py-8  border-gray-200 md:py-16 lg:py-24 xl:py-40 xl:px-0">
          <div class="container flex flex-col items-center justify-between h-full max-w-6xl mx-auto">
            <h2 class="my-5 text-base font-medium tracking-tight text-orange-500 uppercase">
              FAQs
            </h2>
            <h2
              class="text-4xl font-extrabold text-center leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              Frequently Asked Questions
            </h2>

            <div class="max-w-full mx-auto mt-8 md:max-w-9xl sm:px-3">
              <Accordion :multiple="true" :activeIndex="[0]">
                <AccordionTab header="How do I book a room?">
                  <p>
                    To begin your booking, simply click on the "Book a Room" button and provide your details along with
                    the required application processing or booking fee. You will promptly receive an email confirming
                    your booking. Within seven (7) working days, an acceptance email will be sent to you, indicating
                    that your booking has been approved. This email will include instructions for payment of hostel
                    fees, as well as any other pertinent information you may need.
                  </p>
                </AccordionTab>
                <AccordionTab header="Can I select a room I like?">
                  <p>
                    Absolutely! When you go through the booking process, you will have the opportunity to choose the
                    room category that suits your preferences. After your booking is accepted and payment is made, our
                    hostel administrator will allocate you to a room that falls within the type you selected during the
                    booking. Rest assured that we strive to accommodate your preferences and provide you with a room
                    that aligns with your selection.

                  </p>
                </AccordionTab>
                <AccordionTab header="How do I pay booking fee?">
                  <p>
                    You can pay your booking fee via mobile money (all networks) or bank credit/debit card.
                  </p>
                </AccordionTab>
                <AccordionTab header="Is the booking fee non-refundable?">
                  <p>
                    No, the booking fee is non-refundable under all circumstances
                  </p>
                </AccordionTab>
                <AccordionTab header="Can I cancel a booking?">
                  <p>
                    Yes, you can cancel your booking if you need to do so. However, booking fee is not refundable
                  </p>
                </AccordionTab>
                <AccordionTab header="Do I need to pay a deposit? How much is the deposit?">
                  <p>
                    The only deposit you need to pay when booking is your booking/ application processing fee. Within
                    seven (7) working days from paying your application processing fee, you will receive an email
                    containing instructions on paying the hostel fees
                  </p>
                </AccordionTab>
                <AccordionTab
                  header="How long after I have received my booking acceptance email will my booking be revoked if I do not pay the hostel fees?">
                  <p>
                    Your booking will automatically be revoked after 5 working days from receiving your booking
                    acceptance email if you do not pay our hostel fees. Note that your booking fee will not be refunded
                  </p>
                </AccordionTab>
                <AccordionTab header="What do I do next after paying my hostel fees?">
                  <p>
                    After paying your hostel fees, login to this portal and upload the payment receipt in a clear,
                    legible way. The hostel administrator will review the receipt and allocate a room to you. The
                    details of your room allocation will be sent to your email
                  </p>
                </AccordionTab>
                <AccordionTab header="What happens if I do not receive my booking confirmation email?">
                  <p>
                    This means you potentially entered a wrong email. Contact the hostel administrator if this happens.
                  </p>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </div>


      </div>
    </section>
  </main>
</template>
<script setup>
  import { onMounted } from "vue";
  import { useApp } from "@/composables/app";
  import { useAuth } from "@/composables/auth";
  import { useRouter } from 'vue-router';
  const props = defineProps({
    pageName: {
      type: String,
      default: "index",
    },
    routeName: {
      type: String,
      default: "index",
    },
  });
  const app = useApp();
  const auth = useAuth();
  const router = useRouter();

  function images() {
    let images = [];
    for (let i = 1; i < 7; i++) {
      images.push(`images/rm${i}.jpg`);
    }
    return images;
  }
  function showRooms() {
    const nextUrl = '/rooms/categories';
    if (auth.isLoggedIn) {
      router.push(nextUrl);
    } else {
      router.push(`/index/login?nexturl=${nextUrl}`);
    }
  }
  onMounted(() => {
    const pageTitle = process.env.VUE_APP_NAME;
    app.setPageTitle(props.routeName, pageTitle);
  });
</script>
<style></style>